<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <validation-observer ref="modalObserver" v-slot="{ handleSubmit }">
        <v-form
          class="newClass"
          @submit.prevent="
            handleSubmit(
              dataReceived.title === 'Add New Hotel'
                ? addNewHotel
                : updateExistingHotel
            )
          "
          ref="modalForm"
        >
          <v-card-title class="card-title-dialog">
            <span class="text-h6">{{ dataReceived.title }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Name"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Name"
                      v-model="name"
                      class="form-control"
                      placeholder="Name"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-account"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Website"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Website"
                      v-model="website"
                      class="form-control"
                      placeholder="Website"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-web"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Phone Number"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Phone Number"
                      class="form-control"
                      placeholder="Phone Number"
                      required
                      outlined
                      :value="acceptNumber(phoneNumber)"
                      @keypress="onlyNumbers"
                      @input="phoneNumber = $event.replaceAll('-', '')"
                      hint="The Phone format is XXX-XXX-XXXX"
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-phone"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field
                  ></validation-provider>
                </v-col>
                <v-col cols="12">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Address"
                    rules="required"
                    mode="lazy"
                  >
                    <v-text-field
                      label="Address"
                      v-model="address"
                      class="form-control"
                      placeholder="Address"
                      required
                      outlined
                      :hide-details="!errors.length"
                      :error-messages="errors"
                      prepend-icon="mdi-map-marker"
                      :disabled="loading"
                      data-vv-validate-on="change"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12">
                  <div class="d-flex parentClass">
                    <v-icon>mdi-map-marker-radius</v-icon>
                    <GmapAutocomplete
                      id="glocation"
                      class="glocation"
                      @place_changed="setPlace"
                      :value="location"
                    />
                  </div>
                </v-col>
                <v-col cols="12">
                  <!-- <button @click="addMarker">Add</button> -->
                  <GmapMap
                    class="form-control"
                    :center="center"
                    :zoom="12"
                    style="
                      margin-top: 10px;
                      width: 90%;
                      height: 200px;
                      margin-left: 35px;
                      padding: 30px;
                    "
                  >
                    <GmapMarker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      @click="center = m.position"
                    />
                  </GmapMap>
                </v-col>

                <v-col cols="12">
                  <validation-provider name="Active Event" mode="lazy">
                    <v-checkbox
                      label="Active Hotel"
                      hide-details
                      class="mt-n2"
                      v-model="isActive"
                    ></v-checkbox>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="$emit('disableDialog', false), resetForm()"
            >
              Close
            </v-btn>
            <v-btn
              v-if="dataReceived.title == 'Add New Hotel'"
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Add
            </v-btn>
            <v-btn
              v-else
              color="add-btn"
              type="submit"
              text
              :disabled="loading"
              :loading="loading"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-form>
      </validation-observer>
    </v-card>
    <!-- </template> -->
  </v-dialog>
</template>

<script>
import HotelService from "../../services/HotelService.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { gmapApi } from "vue2-google-maps";
export default {
  watch: {
    dataReceived: {
      handler: function () {
        if (this.dataReceived.title === "Update Hotel") {
          this.id = this.dataReceived.hotelId;
          this.name = this.dataReceived.name;
          this.website = this.dataReceived.website;
          this.address = this.dataReceived.address;
          this.phoneNumber = this.dataReceived.phoneNumber;
          this.isActive = this.dataReceived.isActive;
          this.center.lat = this.dataReceived.latitude;
          this.center.lng = this.dataReceived.longitude;
          this.markers.push({ position: this.center });
          this.placeId = this.dataReceived.googlePlaceId;
          this.$gmapApiPromiseLazy().then(() => {
            if (this.google) {
              this.geocoder = new this.google.maps.Geocoder();
              this.geocode(this.placeId);
            }
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    google: gmapApi,
  },
  // async created() {

  //   await this.$gmapApiPromiseLazy().then(() => {
  //     if (this.google) {
  //       this.geocoder = new this.google.maps.Geocoder();
  //       this.geocode();
  //     }
  //   });
  // },
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dataReceived: {
      type: Object,
    },
  },
  data: () => ({
    isLocation: false,
    geocoder: "",
    id: 0,
    name: "",
    website: "",
    address: "",
    phoneNumber: "",
    latitude: 0,
    longitude: 0,
    isActive: true,
    actionBy: 1,
    loading: false,
    location: "",
    center: { lat: 26.9124336, lng: 75.7872709 },
    currentPlace: null,
    markers: [],
    place: "",
  }),

  methods: {
    async addNewHotel() {
      try {
        this.loading = true;
        const response = await HotelService.addHotel({
          id: this.id,
          name: this.name,
          website: this.website,
          address: this.address,
          phoneNumber: this.phoneNumber,
          latitude: this.center.lat,
          longitude: this.center.lng,
          isActive: this.isActive,
          actionBy: this.actionBy,
          googlePlaceId: this.placeId,
        });

        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Hotel is added Successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadHotel");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async updateExistingHotel() {
      try {
        this.loading = true;
        const response = await HotelService.updateHotel({
          id: this.id,
          name: this.name,
          website: this.website,
          address: this.address,
          phoneNumber: this.phoneNumber,
          latitude: this.center.lat,
          longitude: this.center.lng,
          isActive: this.isActive,
          actionBy: this.actionBy,
          googlePlaceId: this.placeId,
        });
        if (response.data.errorMessage == "") {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: "Hotel is updated Successfully",
              type: "success",
            },
            { root: true }
          );
          this.resetForm();

          this.$emit("disableDialog", false);
        } else {
          this.$store.dispatch(
            "notifications/setNotification",
            {
              text: response.data.errorMessage,
              type: "error",
            },
            { root: true }
          );
        }
        this.$emit("reloadHotel");
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.center = marker;
        this.markers.push({ position: marker });
        // this.latitude = marker.lat;
        // this.longitude = marker.lng;
        this.placeId = this.currentPlace.place_id;
        this.location = this.currentPlace.formatted_address;
        this.currentPlace.formatted_address.includes(this.currentPlace.name)
          ? this.currentPlace.formatted_address
          : this.currentPlace.name + ", " + this.currentPlace.formatted_address;

        this.currentPlace = null;
      }
    },
    resetForm() {
      this.$refs.modalObserver.reset();
      // this.$refs.modalForm.reset();
      (this.id = 0), (this.website = "");
      this.name = "";
      this.phoneNumber = "";
      this.address = "";
      this.latitude = 0;
      this.longitude = 0;
      this.isActive = true;
      this.actionBy = 1;
      this.loading = false;
      this.location = "";
      this.center = { lat: 26.9124336, lng: 75.7872709 };
      this.currentPlace = null;
      this.markers = [];
      // this.placeId = "";
    },
    acceptNumber(value) {
      if (value !== null && value !== "" && value !== 0) {
        const x = String(value)
          .replace(/\D/g, "")
          .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        return !x[2]
          ? `${x[1]}`
          : `${x[1]}-${x[2]}${x[3] ? `${"-" + x[3]}` : ""}`;
      }
    },
    onlyNumbers(event) {
      const keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    geocode() {
      this.geocoder.geocode({ placeId: this.placeId }, (results) => {
        this.location = results[0].formatted_address;
      });
    },

    // const infowindow = new google.maps.InfoWindow();

    // geocodePlaceId(geocoder, id) {
    //   const placeId = id;
    //   geocoder
    //     .geocode({ placeId: placeId })
    //     .then(({ results }) => {
    //       if (results[0]) {
    //         console.log(results[0]);
    //         // // map.setZoom(11);
    //         // // map.setCenter(results[0].geometry.location);

    //         // const marker = new google.maps.Marker({
    //         //   map,
    //         //   position: results[0].geometry.location,
    //         // });

    //         // infowindow.setContent(results[0].formatted_address);
    //         // infowindow.open(map, marker);
    //       } else {
    //         window.alert("No results found");
    //       }
    //     })
    //     .catch((e) => window.alert("Geocoder failed due to: " + e));
    // },

    // window.initMap = initMap;
  },
};
</script>
<style scoped>
.glocation {
  border: 1px solid #a4a4a4;
  padding: 10px;
  margin-left: 7px;
  width: 94%;
  border-radius: 5px;
  font-size: 16px;
}
.parentClass.focus {
  color: #efce6a;
}
.parentClass {
  color: #a4a4a4;
}
.glocation:focus-visible,
.glocation:focus {
  border: 2px #efce6a solid !important;
  outline: none;
  box-shadow: none;
}
</style>
